<script setup lang="ts">
import dayjs from "dayjs";

import type { Promotion, Tournament, Badge } from "@/types";

const { t } = useT();

const { open } = useNlcModals();

const BADGE = {
	labelBackground: "#0025E4",
	labelBorderColor: "var(--overlay-light)"
} as Badge;

const props = defineProps<{
	image: string;
	type?: Promotion["type"];
	title: string;
	tournament: Tournament;
	sweepStakes?: boolean;
	subscribed?: boolean;
}>();

const startTournament = dayjs(props.tournament.start).format("h:mm A");

const handleHowItWorksModal = () => {
	open("LazyOModalPromoHowItWorks", { type: props.type });
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 332,
			height: 420,
			src: image,
			format: 'avif',
			alt: 'card-tournament',
			loading: 'lazy'
		}"
		class="card-tournament"
	>
		<template #top>
			<AText
				:size="12"
				:modifiers="['bold', 'uppercase']"
				class="topline flex-center"
				:class="{ 'entries-mode': sweepStakes }"
				as="div"
			>
				{{ sweepStakes ? t("Super Coins mode") : t("Gold Coins mode") }}
			</AText>

			<MPromotionLabel :badge="BADGE" icon="20/prize-tour" :title="title" />

			<div class="card-count">
				<AText class="count-before" :modifiers="['left']">{{ t("Prize Pool") }}</AText>
				<NuxtIcon
					:name="`20/${
						sweepStakes || (typeof tournament.prizes?.[0] === 'object' && tournament.prizes?.[0].coins)
							? 'entries'
							: 'coins'
					}`"
					filled
				/>
				<AText :size="40" :modifiers="['bold']">
					{{ numberFormat(Number(parseInt(tournament.prize || "0"))) }}
				</AText>
			</div>

			<AText v-if="tournament.prizes && tournament.prizes.length" as="ul" :modifiers="['normal']" class="list">
				<li v-for="(prize, index) in tournament.prizes.slice(0, 3)" :key="index">
					<div>
						<NuxtIcon name="20/prize-tour" filled />
						{{ index + 1 }} {{ t("Place") }}
					</div>
					<div>
						<NuxtIcon
							:name="`20/${sweepStakes || (typeof prize === 'object' && prize.coins) ? 'entries' : 'coins'}`"
							filled
						/>
						<b>{{ numberFormat(Number(typeof prize === "object" ? prize.entries : prize) || 0) }}</b>
					</div>
				</li>
			</AText>
		</template>

		<template #default>
			<span class="btn-info" @click.stop="handleHowItWorksModal">
				<NuxtIcon name="16/info" filled />
			</span>
		</template>

		<template #bottom>
			<template v-if="tournament.isActive || tournament.isOpen">
				<MCounterBadgeGroup v-if="tournament.end" :timestamp="tournament.end ?? ''" :badgeText="t('Time left')" />
				<AText class="usual" :modifiers="['bold']">{{ sweepStakes ? t("Free to Play") : "" }}</AText>
			</template>
			<AText v-else :modifiers="['bold']">
				{{ t("Starts at") }} <b>{{ startTournament }}</b>
			</AText>

			<AButton variant="primary" data-tid="promo-tournament-go" :modifiers="['wide']" size="lg">
				<AText :size="16" :modifiers="['uppercase', 'black']">
					{{ subscribed ? t("Play now") : t("Join tournament") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card.card-tournament {
	--a-card-default-top-padding: 20px 16px 0;
	--a-card-default-bottom-padding: 0 16px 16px;
	--a-card-default-top-gap: 16px;
	--a-card-default-bottom-gap: 12px;

	justify-content: space-between;
	background-color: var(--neutral-15);

	&:deep(.bottom) {
		padding-top: 0;
	}

	&:deep(.top) {
		text-align: center;
		gap: 0;
	}

	.count-before {
		display: inline-flex;
		width: 40px;
	}
}

.btn-info {
	display: inline-flex;
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 16px;
	right: 8px;
	padding: 8px;

	.nuxt-icon {
		display: inline-flex;
		font-size: 24px;

		&:deep(svg) {
			margin: 0;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

.card-count {
	display: flex;
	align-items: center;
	margin: 20px 0 6px;
	gap: 4px;

	&:deep(svg) {
		font-size: 32px;
	}
}

.list {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 284px;

	li {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		border-bottom: 1px solid var(--neutral-50);
		padding: 0 8px;
		height: 36px;

		div {
			display: inline-flex;
			align-items: center;
			gap: 4px;
		}

		img {
			max-width: 20px;
		}

		.nuxt-icon {
			font-size: 20px;
			display: inline-flex;

			svg {
				margin: 0;
			}
		}
	}

	li:last-child {
		border: none;
	}
}

.topline {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 20px;
	background: var(--tertiary-80);
	color: var(--neutral-5);

	&.entries-mode {
		background: var(--secondary-60);
		color: var(--primary-100);
	}
}

.usual {
	display: inline-flex;
	min-height: 19px;
}
</style>
